


































































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import { boolean } from 'yargs';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import DrugName from '~/components/DrugName.vue';
import LayoutModule from '~/store/layout';

@Component<ISIInfo>({
    components: {
        DrugName,
    },
    icons: {
        faPlus,
        faMinus,
    },
})
export default class ISIInfo extends Vue {
    // font-family: Helvetica, sans-serif, Arial, serif;
    @Resolve
    public layout!: LayoutModule;

    @Prop({ type: Boolean, default: false })
    showMore!: boolean;
    showMoreText = true;
    get showText() {
        return this.showMoreText ? 'SHOW MORE' : 'SHOW LESS';
    }
    termsConds = this.$refs.termsConds
    mounted() {
        console.log(this.showMore);
    }
}

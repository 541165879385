export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    headerOdactraLogo: string;
    headerGrastekLogo: string;
    headerRagwitekLogo: string;
    headerLogoLeft: string;
    headerLogoRight: string;
    footerLogo: string;
    programId: string;
    surveyId: string;
    offer: string;
    alkNetworkPharmacy:string;
    localRetailPharmacy:string;
    consentYes:string;
    consentNo:string;
    consent:string;
    lockEnabled: string;
    lockPassword: string;

}

import { email } from 'vuelidate/lib/validators';
import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnrollmentResultModelResponse,
    EnrollmentRequestModel,
} from '@trialcard/apigateway.models';
import { AccountExternalIdType, Gender, RoleType } from '@trialcard/enums';

import moment, { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeCouponApi, EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import ActivationModule from './activation';
import { Settings } from '~/services/settings';
import EligibilityModule from '~/store/eligibility';
import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates'
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers'

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public eligibility!: EligibilityModule;

    @Resolve
    public EdgeCouponApi!: EdgeCouponApi;
    memberNumber=''
    defaultMemberNumber='xxxxxxxxxxx'
    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender:  Gender.UNKN,
    };
    public demographicInformation = {
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        email: '',
        homePhone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
    };
    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    patientInformationData = { ...this.defaultPatientInformationData };




    contactPreferences = [...this.defaultContactPreferences];



    @Mutation
    public updateDemographicInformation(data: typeof EnrollmentModule.prototype.demographicInformation) {
        Object.assign(this.demographicInformation, data);
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }
    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }



    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }





    @Action({ rawError: true })
    public clearStore() {

        this.eligibility.updateEligibilityData([]);

        this.updateContactPreferences(this.defaultContactPreferences);
        this.eligibility.clearStore();
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({})
    public async submitBasicEnrollment() {
        let response = { success: false } as EnrollmentResultModelResponse;

        const request = {
            survey: this.eligibility.eligibilityData,
            demographics: this.demographicInformation,
        };
        try {
            response = (await this.EdgeCouponApi.enrollmentBasicEnrollment(
                +this.settings.programId,
                request as EnrollmentRequestModel
            )).data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error from enrollment:',error);
        }
        return response;
    }


    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;

        const { contactPreferences } = this;
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,

                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,

            } as EnrollPatientRequestModel;
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.programId);
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }

}

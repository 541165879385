

































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Resolve } from 'vue-di';
import LayoutModule from '../store/layout';
import Header from '~/components/Header/Header.vue';
import Footer from '~/components/Footer/Footer.vue';
import FloatingFooter from '~/components/Footer/FloatingFooter.vue';
import StaticFooter from '~/components/Footer/StaticFooter.vue';
import ScrollingFooter from '~/components/Footer/ScrollingFooter.vue';

@Component<StepLayout>({
    components: {
        Header,
        Footer,
        StaticFooter,
        ScrollingFooter,
        FloatingFooter,
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((this as any).$meta) (this as any).$meta().refresh();
    },
})
export default class StepLayout extends Vue {
    @Resolve
    public layout!: LayoutModule;

    public activateACardPath = window.location.href.includes('activate-a-card');
    public enrollmentPath = window.location.href.includes('enrollment');
    get currentStep() {
        if (this.activateACardPath) {
            switch (this.$store.state.route.name) {
                case 'activate-a-card-member-number':
                    return 1;
                case 'activate-a-card-eligibility':
                    return 2;
                case 'activate-a-card-patient-information':
                    return 3;
                case 'activate-a-card-insurance':
                    return 4;
                case 'thank-you':
                    return 5;
                default:
                    return 1;
            }
        } else {
            switch (this.$store.state.route.name) {
                case 'enrollment-eligibility':
                    return 1;
                case 'enrollment-patient-information':
                    return 2;
                case 'enrollment-insurance':
                    return 3;
                case 'thank-you':
                    return 4;
                default:
                    return 1;
            }
        }
    }

    public back() {
        this.$router.back();
    }
    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bold{font-weight:700}.alk-ISI-container{padding:10px}@media only all and (max-width:400px){.alk-ISI-container{max-width:300px;padding:10px;overflow:visible!important}}#showMore{font-size:20px;color:#133865}@media only all and (max-width:400px){#showMore{font-size:16px}}.show-color{color:red}#alk-ISI{margin:-15px;background-color:#fff;border-top:1px solid #133865;position:fixed;transition:all 1s ease;max-height:160px;bottom:0;z-index:100;max-width:900px;box-sizing:border-box}@media only all and (max-width:400px){#alk-ISI{font-size:14px}}#alk-ISI.toggle{overflow-y:scroll;max-height:98%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0e0078",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
